import React from "react";
import Form from "./form";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import { checkout } from "../services/checkoutService";
import { createTicket } from "../services/ticketService";
import { getCurrentUser } from "../services/authService";
import "./checkoutForm.css";

const queryString = require("query-string");
class Checkout extends Form {
  state = {
    loading: true,
    quantity: 0,
    price: 0.0,
    organization: "",
    member: "",
    data: {
      cardNumber: "",
      expirationDate: "",
      cardCode: "",
    },
    errors: {},
  };

  schema = {
    cardNumber: Joi.string().min(13).max(16).required(),
    expirationDate: Joi.string().min(4).max(4).required(),
    cardCode: Joi.string().min(3).max(4).required(),
  };

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    // Create local copy of state
    let state = { ...this.state };

    // Get transaction information from URL
    let query = this.props.location.search;
    if (!query) {
      toast.error("Invalid URL, no checkout information");
      return;
    }
    let parsed = queryString.parse(query);
    state.quantity = parsed.quantity ? parsed.quantity : 0;
    state.organization = parsed.organization
      ? parsed.organization
      : "Not Specified";
    state.member = parsed.member ? parsed.member : "Not Specified";

    // Get user information from query
    if(parsed.logged_in == "true") {
      state.loggedIn = true;
      state.user = await getCurrentUser();
    } else {
      state.loggedIn = false;
      state.user = {
        id: null,
        authLevel: "user",
        name: parsed.name,
        email: parsed.email,
        phone: parsed.phone
      }
    }

    // Calculate price from quantity
    let quantity = state.quantity;
    while (quantity > 0) {
      // $100 per 30 tickets
      if (quantity >= 30) {
        state.price += 100;
        quantity -= 30;
        // $20 per 5 tickets
      } else if (quantity >= 5) {
        state.price += 20;
        quantity -= 5;
        // $5 per ticket
      } else {
        state.price += 5;
        quantity -= 1;
      }
    }

    state.loading = false;

    // Set globabl state of component
    this.setState(state);
  };

  doSubmit = async () => {
    // Create local copy of state
    let state = { ...this.state };

    // Set state to loading
    state.loading = true;
    this.setState(state);

    // Pull data from state
    let { quantity, price, organization, member, data, user, loggedIn } = state;
    let { cardNumber, expirationDate, cardCode } = data;

    // Send transaction request to API and handle errors
    try {
      await checkout(
        quantity,
        price,
        organization,
        member,
        user,
        cardNumber,
        expirationDate,
        cardCode
      );
    } catch (ex) {
      if (ex.response) {
        toast.error(
          `Transaction Failed: 
            ${ex.response.data.transactionResponse.errors.error[0].errorText}`,
          { autoClose: 15000 }
        );
      }

      this.state.loading = false;
      this.setState(state);
      return;
    }
    // Create ticket and handle errors
    try {
      await createTicket(user, organization, member, quantity);
    } catch (ex) {
      toast.error(
        `! DO NOT CLOSE THESE MESSAGES
          Your transaction was processed, but there was an error creating your ticket(s). Please take a screenshot of these messages and send us an e-mail at rotarysnowball@gmail.com!`,
        { autoClose: false }
      );
      toast.error(`INFORMATION:`, { autoClose: false });
      toast.error(`Name: ${user.name}`, { autoClose: false });
      toast.error(`Phone: ${user.phone}`, { autoClose: false });
      toast.error(`Quantity: ${quantity}`, { autoClose: false });
      toast.error(`Organization: ${organization}`, { autoClose: false });
      toast.error(`Seller: ${member}`, { autoClose: false });

      this.state.loading = false;
      this.setState(state);
      return;
    }

    // Confirm purchase and redirect to home
    toast.success(
      "Your purchase was successful!\n\n" + ( 
        loggedIn
        ? "If you'd like to view your tickets, click your name in the upper right corner of the page to go to account settings."
        : "You should get a reciept in your email. If your ticket is a winner, your name will be announced at the event and we will reach out to notify you."
      ),
      { autoClose: 15000 }
    );
    this.props.history.push("/");
  };

  render() {
    return (
      <div>
        {this.state.loading ? (
          <h1
            style={{ textAlign: "center", margin: "10%", marginBottom: "40px" }}
          >
            Loading...
          </h1>
        ) : (
          <MDBContainer className="container">
            <h1>Checkout</h1>
            <div className="body">
              <MDBRow>
                <MDBCol md="6">
                  <form className="customerInfo" onSubmit={this.handleSubmit}>
                    <p className="formTitle">
                      Contact information:
                    </p>
                    <div style={{textAlign: "center"}}>
                      <p>Full Name: {this.state.user.name}</p>
                      <p>E-mail Address: {this.state.user.email}</p>
                      <p>Phone Number: {this.state.user.phone}</p>
                    </div>
                    <p className="formTitle">
                      Please enter your payment information:
                    </p>
                    <p className="disclaimer">
                      We don't save ANY of your payment information.
                    </p>
                    {this.renderInput(
                      "cardNumber",
                      "Card Number",
                      null,
                      "The 13 to 16 digit number on your credit/debit. Please do not add spaces or other characters.",
                      8
                    )}
                    {this.renderInput(
                      "expirationDate",
                      "Expiration Date",
                      "text",
                      "MMYY",
                      8
                    )}
                    {this.renderInput(
                      "cardCode",
                      "Security Code",
                      "text",
                      "3-4 digit number, usually on the back of the card",
                      8
                    )}
                    {
                      this.state.loggedIn ? ''
                      : <p className="disclaimer">
                          If you do not log in before purchasing, you will not be able to view your tickets.
                        </p>
                    }
                    <button
                      disabled={this.validate()}
                      className="checkout"
                      type="submit"
                    >
                      Checkout
                    </button>
                  </form>
                </MDBCol>
                <MDBCol md="6" className="transactionInfo">
                  <p className="quantity">Quantity: {this.state.quantity}</p>
                  <p className="price">Price: ${this.state.price.toFixed(2)}</p>
                  <p>Organization: {this.state.organization}</p>
                  <p>Seller: {this.state.member}</p>
                </MDBCol>
                
              </MDBRow>
            </div>
          </MDBContainer>
        )}
      </div>
    );
  }
}

export default Checkout;
