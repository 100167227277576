import React from "react";
import {
  List,
  Edit,
  Filter,
  Create,
  Datagrid,
  TextField,
  ImageField,
  EditButton,
  ImageInput,
  SelectInput,
  SimpleForm,
  TextInput,
  CardActions,
  CreateButton,
  ExportButton,
} from "react-admin";

const SponsorFilter = (props) => (
  <Filter {...props}>
    <TextInput source="name" />
  </Filter>
);

const SponserActions = ({
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  resource,
  showFilter,
  total,
  basePath
}) => (
<CardActions>
    {filters && React.cloneElement(filters,{
      resource,
      showFilter,
      displayedFilters,
      filterValues,
      context: 'button',
    })}
    <CreateButton basePath={basePath}/>
    <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        //Set maxresults for exporter button function (react admin)
        maxResults={100000}
        filter={filterValues}
        exporter={exporter}
    />

</CardActions>
);

export const SponsorList = (props) => {
  return (
    <List actions={<SponserActions />} create={<SponsorCreate />} filters={<SponsorFilter />} {...props}>
      <Datagrid>
        <TextField source="name" />
        <ImageField source="logo" />
        <TextField source="priority" />
        <EditButton />
      </Datagrid>
    </List>
  );
};

const SponsorTitle = ({ record }) => {
  return <span>{`${record.name}`}</span>;
};

const PreviewImage = ({ record, source }) => {
  if (typeof record == "string") {
    record = {
      [source]: record,
    };
  }
  return <ImageField record={record} source={source} />;
};

export const SponsorEdit = (props) => {
  return (
    <Edit undoable={false} title={<SponsorTitle />} {...props}>
      <SimpleForm>
        <TextInput source="name" />
        <ImageInput source="logo" accept="image/*">
          <PreviewImage source="src" />
        </ImageInput>
        <SelectInput
          title="Sponsor Priority"
          source="priority"
          choices={[
            { id: "special", name: "Special" },
            { id: "prize", name: "Prize" },
            { id: "media", name: "Media" },
            { id: "in-kind", name: "In-Kind" },
          ]}
        />
      </SimpleForm>
    </Edit>
  );
};

export const SponsorCreate = (props) => (
  <Create {...props}>
    <SimpleForm redirect="list">
      <TextInput source="name" />
      <ImageInput source="logo" accept="image/*">
        <ImageField source="src" title="title" />
      </ImageInput>
      <SelectInput
        title="Sponsor Priority"
        source="priority"
        choices={[
          { id: "special", name: "Special" },
          { id: "prize", name: "Prize" },
          { id: "media", name: "Media" },
          { id: "in-kind", name: "In-Kind" },
        ]}
      />
    </SimpleForm>
  </Create>
);
