import React from "react";
import {
  List,
  Edit,
  Filter,
  Create,
  Datagrid,
  TextField,
  UrlField,
  ImageField,
  EditButton,
  ImageInput,
  SimpleForm,
  TextInput,
  useGetOne,
  Loading,
  CardActions,
  ExportButton,
  CreateButton
} from "react-admin";

const CharityFilter = (props) => (
  <Filter {...props}>
    <TextInput source="name" />
    <TextInput label="Administator" source="charityAdmin" />
  </Filter>
);

const CharityActions = ({
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  resource,
  showFilter,
  total,
  basePath
}) => (
<CardActions>
    {filters && React.cloneElement(filters,{
      resource,
      showFilter,
      displayedFilters,
      filterValues,
      context: 'button',
    })}
    <CreateButton basePath={basePath}/>
    <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        //Set maxresults for exporter button function (react admin)
        maxResults={100000}
        filter={filterValues}
        exporter={exporter}
    />

</CardActions>
);

export const AdminCharityList = (props) => {
  return (
    <List actions={<CharityActions />} create={<CharityCreate />} filters={<CharityFilter />} edit={<CharityEdit />} {...props}>
      <Datagrid>
        <TextField source="name" />
        <UrlField source="website" />
        <TextField label="Administator Email" source="charityAdmin" />
        <EditButton />
      </Datagrid>
    </List>
  );
};

const CharityTitle = ({ record }) => {
  return <span>{`${record.name}`}</span>;
};

const PreviewImage = ({ record, source }) => {
  if (typeof record == "string") {
    record = {
      [source]: record,
    };
  }
  return <ImageField record={record} source={source} />;
};

export const CharityEdit = (props) => {
  const { data } = useGetOne("charities", props.id);
  return (
    <Edit undoable={false} title={<CharityTitle />} {...props}>
      {
        data ? (
          props.permissions.role === "admin" ||
          props.permissions.role === "master" ||
          props.permissions.name === data.admin ? (
            <SimpleForm>
              <TextInput source="name" />
              <ImageInput source="banner" accept="image/*">
                <PreviewImage source="src" />
              </ImageInput>
              <TextInput source="website" />
              <TextInput multiline label="Description" source="body" />
              <TextInput label="Administrator Email" source="charityAdmin" />
            </SimpleForm>
          ) : (
            <div>
              <h1>You do not have permission to edit this record.</h1>
              <h1>
                You may only edit charity pages for which you are the administrator.
              </h1>
            </div>
          )
        ) : (
          <Loading />
        )
      } 
    </Edit>
  );
};

export const CharityCreate = (props) => (
  <Create {...props}>
    <SimpleForm redirect="list">
      <TextInput source="name" />
      <ImageInput source="banner" accept="image/*">
        <ImageField source="src" title="title" />
      </ImageInput>
      <TextInput source="website" />
      <TextInput multiline label="Description" source="body" />
      <TextInput label="Administrator Email" source="charityAdmin" />
    </SimpleForm>
  </Create>
);
